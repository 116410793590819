
import {defineComponent} from "vue";
import RouterNavigate from "@/components/base/common/RouterNavigate.vue";

export default defineComponent({
  name: "TrainingLayout",
  components: {RouterNavigate},
  setup() {

  }
})
