import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterNavigate = _resolveComponent("RouterNavigate")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_RouterNavigate, { items: [
      {name:'Training Progress', uri:'/training/progress'},
      {name:'All Trainings', uri:'/training/all'},
  ] }),
    _createVNode(_component_RouterView)
  ], 64))
}